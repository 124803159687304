import Vue from "vue/dist/vue.esm";
import {gsap, CSSPlugin, Power4, Linear, Back, Elastic} from "gsap";

gsap.registerPlugin(CSSPlugin);

Vue.component('slide-news', {
    props: ['slide'],
    data: function () {
        return {
            news: [],
            currentNewsItemIndex: 0,
            newsRequiresUpdate: false
        }
    },
    template: `
      <div class="rn7-slide rn7-slide-news">
      <div class="rn7-news-prefix">Nu op<img src="images/rn7.nl_white2.png" alt="rn7.nl" class="img-fluid">
      </div>
      <div class="news-wrapper row align-items-center no-gutters">
        <div class="col-7 newsTitleColumn">
          <h1>{{ currentNewsTitle }}</h1>
        </div>
        <div class="col-5 overflow-hidden">
          <img :src="currentNewsImage" alt="Nieuws" class="img-fluid news-photo">
        </div>
      </div>
      </div>`,
    mounted() {
        this.updateNews();
    },
    activated() {
        let timeline = gsap.timeline({onComplete: this.emitNext});
        timeline
            .fromTo(".newsTitleColumn", {css: {x: -100, opacity: 0}}, {
                css: {x: 0, opacity: 1},
                ease: Power4.easeOut,
                duration: 3
            })
            .fromTo(".news-photo", {css: {scale: 1}},
                {css: {scale: 1.15}, ease: Linear.easeNone, duration: 15}, "-=3");
        // .to(".unsplash-photo", {css: {opacity: 0}, duration: 1});
    },
    deactivated() {
        if (this.newsRequiresUpdate) {
            this.newsRequiresUpdate = false;
            this.updateNews();
        }

        setTimeout(()=>{
            if (this.news.length > 0) {
                this.currentNewsItemIndex = (this.currentNewsItemIndex + 1) % this.news.length;
            } else {
                this.currentNewsItemIndex = 0;
            }
        }, 5000);
    },
    methods: {
        updateNews() {
            let request = new XMLHttpRequest();
            request.responseType = "xml";
            request.addEventListener("load", (event) => {
                console.log(event.target.responseXML.getElementsByTagName("item"));
                this.news = Array.from(event.target.responseXML.getElementsByTagName("item")).map((item) => {
                    return {
                        title: item.getElementsByTagName("title").item(0).innerHTML,
                        readMore: item.getElementsByTagName("description").item(0).innerHTML,
                        image: item.getElementsByTagName("media:thumbnail").item(0).getAttribute("url")
                    };
                });
                this.error = false;
                console.log(this.news);
            });
            request.addEventListener("error", (event) => {
                console.log(event);
                this.error = true;
            });
            request.open("GET", "https://rn7scherm.mathijsvos.nl/newsproxy.php");
            request.send();
            setTimeout(() => {
                this.newsRequiresUpdate = true;
            }, 1000 * 60 * 15);
        },
        emitNext() {
            this.$emit('next');
        }
    },
    computed: {
        currentNewsTitle() {
            if (this.news.length === 0) {
                return "";
            }

            return this.news[this.currentNewsItemIndex].title;
        },
        currentNewsBody() {
            if (this.news.length === 0) {
                return "";
            }

            return this.news[this.currentNewsItemIndex].readMore;
        },
        currentNewsImage() {
            if (this.news.length === 0) {
                return "";
            }

            return this.news[this.currentNewsItemIndex].image;
        }
    }
});