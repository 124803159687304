import Vue from "vue/dist/vue.esm";

Vue.component('slide-random-video', {
    props: ['slide'],
    data: function () {
        return {
            availableVideos: [],
            currentVideoIndex: 0,
            startVideo: false,
            playbackStarted: false
        }
    },
    template: `
      <div class="rn7-slide-video">
      <video width="100%" autoplay muted ref="videoPlayer" v-if="startVideo" v-on:ended="emitNext"
             v-on:stalled="emitNext" v-on:play="notifyPlaybackStarted">
        <source :src="slide.sourceUrl + availableVideos[currentVideoIndex]">
      </video>
      </div>`,
    created() {
        this.updateAvailableVideos();
    },
    mounted: function () {

    },
    activated() {
        if (this.availableVideos.length === 0) {
            this.emitNext();
            return;
        }

        this.currentVideoIndex = (this.currentVideoIndex + 1) % this.availableVideos.length;
        this.playbackStarted = false;
        this.startVideo = true;
        setTimeout(this.checkPlayback, 2000);
    },
    deactivated() {
        this.startVideo = false;
    },
    methods: {
        emitNext() {
            this.$emit('next');
        },
        notifyPlaybackStarted() {
            this.playbackStarted = true;
        },
        checkPlayback() {
            if (!this.playbackStarted) {
                this.emitNext();
            }
        },
        updateAvailableVideos() {
            let request = new XMLHttpRequest();
            request.responseType = "document";
            request.addEventListener("load", (event) => {
                this.availableVideos = Array.from(event.target.responseXML.getElementsByTagName("a")).map((element) => {
                    return element.getAttribute("href");
                }).filter((element) => {
                    return element.endsWith(".mp4");
                });
                
                setTimeout(() => {
                    this.updateAvailableVideos();
                }, 1000 * 3600);
            });

            request.addEventListener("error", (event) => {
                setTimeout(() => {
                    this.updateAvailableVideos();
                }, 60000);
            });

            request.open("GET", this.slide.sourceUrl);
            request.send();
        }
    }
});