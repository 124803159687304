import Vue from "vue/dist/vue.esm";
import {gsap, CSSPlugin, Power4, Power0, Back, Elastic} from "gsap";

gsap.registerPlugin(CSSPlugin);

Vue.component('slide-logo', {
    props: ['slide'],
    data: function () {
        return {}
    },
    template: `<div class="logo-slide">
    <div class="logo-slide-background-animator" ref="animator"></div>
<!-- fill:#233973 -->
    <div class="static-slide">
        <svg version="1.1" id="Laag_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 708.7 368.3" style="enable-background:new 0 0 708.7 368.3; filter: drop-shadow(3px 3px 150px rgb(35, 57, 115));" xml:space="preserve" ref="logoSvg">
<g>
	<g id="logo-svg-r">
		<path d="M80.9,83.7c41.5,0,73.2,7.5,95.3,22.5c22.1,15,33.1,38.4,33.1,70c0,19.8-4.5,35.8-13.4,48.1
			c-8.9,12.3-21.7,22-38.5,29c5.6,7,11.4,15.1,17.5,24.2c6.1,9.1,12.2,18.5,18.1,28.4c6,9.9,11.8,20.2,17.3,30.9
			c5.6,10.7,10.8,21.2,15.5,31.5H154c-5.1-9.2-41.3-86.2-63.6-102.7H72v102.7H0V91c13.6-2.7,27.6-4.6,42.1-5.7
			C56.5,84.2,69.5,83.7,80.9,83.7z M89.6,137.6c-4.5,0-3.9,0.1-7.5,0.4c-3.6,0.3-7,0.5-10.2,0.8v76.3h14.4c23.4,0,33.5-3,43.6-8.9
			c10.1-6,15.1-16.1,15.1-30.4c0-13.8-5.1-23.6-15.3-29.4C119.5,140.6,109.6,137.6,89.6,137.6z"/>
	</g>
	<g id="logo-svg-n">
        <path d="M403.9,83.2v0.7h-0.5v155.2c-7.5-15.3-97.9-156-107.6-156h-68.3v285.1h18.1v0H298v0h1
		    c0.6-59.8,0-135.6,0-172.3c58.7,72.7,99.2,158.1,104.7,172.3h0.2h44.3h27.1V83.2H403.9z"/>
    </g>
    <g id="logo-svg-7">
		<linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="636.6299" y1="41.5874" x2="708.6614"
                        y2="83.1748">
			<stop offset="0" style="stop-color:#4EAEE2"/>
            <stop offset="1" style="stop-color:#178FCF"/>
		</linearGradient>
        <polygon style="fill:url(#SVGID_1_);" points="660.6,0 612.6,83.2 708.7,83.2 		"/>
        <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="472.0203" y1="326.7018" x2="544.0518"
                        y2="368.2892">
			<stop offset="0" style="stop-color:#EF3932"/>
            <stop offset="1" style="stop-color:#ED3327"/>
		</linearGradient>
        <polygon style="fill:url(#SVGID_2_);" points="496,285.1 448,368.3 544.1,368.3 		"/>
        <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="508.0355" y1="264.3206" x2="580.0671"
                        y2="305.908">
			<stop offset="0" style="stop-color:#AC1E31"/>
            <stop offset="1" style="stop-color:#AC1E31"/>
		</linearGradient>
        <polygon style="fill:url(#SVGID_3_);" points="592.1,285.1 592.1,285.1 592.1,285.1 544.1,201.9 496,285.1 496,285.1 496,285.1 496,285.1
			496,285.1 544.1,368.3 592.1,285.1 592.1,285.1 		"/>
        <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="554.3232" y1="184.1437" x2="626.3564"
                        y2="225.7321">
			<stop offset="0" style="stop-color:#80366F"/>
            <stop offset="1" style="stop-color:#991B4D"/>
		</linearGradient>
        <polygon style="fill:url(#SVGID_4_);" points="660.6,166.3 564.6,166.3 544,201.9 592.1,285.1 		"/>
        <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="427.304" y1="1.493887e-05" x2="571.3669"
                        y2="83.1748">
			<stop offset="0" style="stop-color:#03A290"/>
            <stop offset="1" style="stop-color:#7EC29D"/>
		</linearGradient>
        <polygon style="fill:url(#SVGID_5_);"
                 points="523.3,0 523.3,0 427.3,0 475.3,83.2 475.3,83.2 571.4,83.2 		"/>
        <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="600.6142" y1="103.9683" x2="672.6457"
                        y2="145.5558">
			<stop offset="0" style="stop-color:#075BA1"/>
            <stop offset="1" style="stop-color:#0679BC"/>
		</linearGradient>
        <polygon style="fill:url(#SVGID_6_);"
                 points="612.6,83.2 612.6,83.2 708.7,83.2 660.6,166.3 660.6,166.3 564.6,166.3 		"/>
        <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="528.5021" y1="-8.9316" x2="631.4734"
                        y2="50.5189">
			<stop offset="0" style="stop-color:#9DBD3B"/>
            <stop offset="1" style="stop-color:#3B9659"/>
		</linearGradient>
        <polygon style="fill:url(#SVGID_7_);" points="660.6,0 619.4,0 564.6,0 523.3,0 571.4,83.2 612.6,83.2 		"/>
	</g>
</g>
</svg>
    </div>
</div>`,
    mounted() {
        let timeline = gsap.timeline({onComplete: this.emitNext});
        timeline
            .to("#logo-svg-7", {
                duration: .5,
                css: {opacity: 1},
                ease: Power4.easeOut
            }, "+=1")
            .to(this.$refs.logoSvg, {
                duration: 1,
                css: {x: 0},
                ease: Power4.easeOut
            }, "+=5")
            .to("#logo-svg-r, #logo-svg-n", {
                duration: 1,
                css: {opacity: 1},
                ease: Power4.easeOut
            }, "-=1")
            .to(this.$refs.animator, {
                duration: 1,
                css: {opacity: 1},
                ease: Power4.easeOut
            }, "+=1")
            .to(this.$refs.logoSvg, {
                duration: 1,
                css: {filter: 'drop-shadow(3px 3px 150px rgb(255 255 255))'},
                ease: Power4.easeOut
            }, "-=1")
            .to(".logo-slide #logo-svg-r path, .logo-slide #logo-svg-n path", {
                duration: 1,
                css: {fill: '#233973'},
                ease: Power4.easeOut
            }, "-=1");

            // .to("#logo-svg-7", {
            //     duration: 1,
            //     css: {opacity: 0},
            //     ease: Power4.easeOut
            // }, "+=0")
            // .to(this.$refs.logoSvg, {
            //     duration: 1,
            //     css: {scale: 1.4, x: 502, y: -157},
            //     ease: Power4.easeOut
            // }, "-=1");
    },
    methods: {
        emitNext() {
            setTimeout(() => {
                this.$emit('next')
            }, 3000);
        }
    }
});