import Vue from "vue/dist/vue.esm";
import {gsap, CSSPlugin, Power4, Power0, Back, Elastic} from "gsap";

gsap.registerPlugin(CSSPlugin);

Vue.component('slide-weather', {
    data: function () {
        return {
            weather: {
                weather: [
                    {
                        id: 0,
                        description: ""
                    }
                ],
                main: {
                    humidity: 0
                },
                visibility: 0,
                wind: {
                    speed: 0,
                    deg: 0
                },
                sys: {
                    sunrise: 0,
                    sunset: 0
                }
            },
            error: false,
            lastUpdate: new Date()
        }
    },
    template: `<div class="rn7-slide rn7-slide-weather">
    <div class="weather-group weather-wind">
        <div><span class="text-center d-inline-block"><i class="wi wi-humidity"></i><br>{{ weather.main.humidity }}</span></div>
        <div><span class="text-center d-inline-block"><i :class="'wi wi-wind from-' + weather.wind.deg + '-deg'"></i><br>{{ weather.wind.speed }}m/s</span></div>
    </div>
    <div class="weather-group weather-temp">
        {{ temp }}<i class="wi wi-celsius"></i>
    </div>
    <div class="weather-group weather-condition">    
        <span class="text-center d-inline-block">
            <i :class="weatherIcon"></i><br>
            <span class="weather-condition-text">{{ weather.weather[0].description }}</span>
        </span>
    </div>
    <div class="weather-group weather-sunrise">
        <div><i class="wi wi-sunrise"></i> {{ sunrise }}</div>
        <div>{{ sunset }} <i class="wi wi-sunset"></i></div>
    </div>
    <div class="weather-group weather-visibility">
        <div v-if="weather.visibility < 1000">Zicht {{ weather.visibility }}m</div>
    </div>
    
    <div class="program-error" v-if="error">
    
    </div>
</div>`,
    mounted() {
        this.updateWeather();
    },
    activated() {
        let timeline = gsap.timeline({onComplete: this.emitNext});
        timeline
            .fromTo(".weather-temp", {css: {opacity: 0, x: -50}},
                {css: {opacity: 1, x: 0}, ease: Power4.easeOut, duration: 1})
            .fromTo(".weather-wind div", {css: {opacity: 0, y: -50}},
                {css: {opacity: 1, y: 0}, ease: Power4.easeOut, duration: 1, stagger: .2}, "-=.8")
            .fromTo(".weather-condition i, .weather-condition-text", {css: {opacity: 0, y: 50}},
                {css: {opacity: 1, y: 0}, ease: Power4.easeOut, duration: 1, stagger: .2}, "-=.8")
            .fromTo(".weather-sunrise div", {css: {opacity: 0, y: 50}},
                {css: {opacity: 1, y: 0}, ease: Power4.easeOut, duration: 1, stagger: .2}, "-=.8")
            .fromTo(".weather-visibility", {css: {opacity: 0, y: 50}},
                {css: {opacity: 1, y: 0}, ease: Power4.easeOut, duration: 1})
            .to(".weather-visibility",
                {css: {opacity: 0, y: 50}, ease: Power4.easeIn, duration: 1}, "+=5")
            .to(".weather-sunrise div",
                {css: {opacity: 0, y: 50}, ease: Power4.easeIn, duration: 1, stagger: .2}, "-=.8")
            .to(".weather-condition i, .weather-condition-text",
                {css: {opacity: 0, y: 50}, ease: Power4.easeIn, duration: 1, stagger: .2}, "-=.8")
            .to(".weather-wind div",
                {css: {opacity: 0, y: -50}, ease: Power4.easeIn, duration: 1, stagger: .2}, "-=.8")
            .to(".weather-temp",
                {css: {opacity: 0, x: 50}, ease: Power4.easeIn, duration: 1});


    },
    deactivated() {
        if (new Date() - this.lastUpdate > 10 * 60 * 1000) {
            this.updateWeather();
        }
    },
    methods: {
        updateWeather() {
            let request = new XMLHttpRequest();
            request.responseType = "json";
            request.addEventListener("load", (event) => {
                this.weather = event.target.response;
                this.error = false;
                this.lastUpdate = new Date();
            });
            request.addEventListener("error", (event) => {
                console.log(event);
                this.error = true;
            });
            request.open("GET", "https://api.openweathermap.org/data/2.5/weather?id=2750053&APPID=18f5bc23ab347d9200c5007d9d2e1fd4&units=metric&lang=nl");
            request.send();
        },
        emitNext() {
            this.$emit('next');
        }
    },
    computed: {
        sunrise() {
            return new Date(this.weather.sys.sunrise * 1000).toLocaleTimeString('nl-NL', {
                'hour': '2-digit',
                'minute': '2-digit'
            }).replace(':', '.');
        },
        sunset() {
            return new Date(this.weather.sys.sunset * 1000).toLocaleTimeString('nl-NL', {
                'hour': '2-digit',
                'minute': '2-digit'
            }).replace(':', '.');
        },
        temp() {
            if (!this.weather.main) {
                return 0;
            }
            return Math.round(this.weather.main.temp * 10) / 10;
        },
        weatherIcon() {
            let useDayNightPrefix = false;
            const iconId = this.weather.weather[0].id;
            if((iconId >= 500 && iconId <= 504) || iconId === 800 || iconId === 801 || iconId === 802)
            {
                useDayNightPrefix = true;
            }

            if(!useDayNightPrefix)
            {
                return 'wi wi-owm-' + iconId;
            }

            const currentDate = new Date();
            if (currentDate > new Date(this.weather.sys.sunrise * 1000) && currentDate < new Date(this.weather.sys.sunset * 1000)) {
                return 'wi wi-owm-day-' + iconId;
            } else {
                return 'wi wi-owm-night-' + iconId;
            }
        }
    }
});