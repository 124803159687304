import Vue from "vue/dist/vue.esm";
import "./slide_program_renderer"
import "./slide_current_program_renderer"

Vue.component('slide-program', {
    data: function () {
        return {
            programs: [],
            programsShown: [],
            currentProgram: {},
            nextProgram: {},
            currentProgramIndex: -1,
            nextProgramIndex: -1,
            error: false,
            reloadScheduled: false,
            showingCurrentProgram: false
        }
    },
    template: `
      <div class="rn7-slide">
      <!--    <transition name="rn7-slide">-->
      <template v-if="showingCurrentProgram">
        <slide-current-program-renderer :current="currentProgram" :next="nextProgram" @next="cyclePrograms"/>
      </template>
      <template v-else>
        <slide-program-renderer v-for="(program, programIndex) in programs" :key="program.id" :program="program"
                                v-if="programIndex === currentProgramIndex" @next="cyclePrograms"/>
      </template>
      <!--    </transition>-->
      <div class="program-error" v-if="error">

      </div>
      <div class="image-preload" v-if="nextProgramIndex >= 0"><img
          :src="programs[nextProgramIndex].presenters[0].picture" alt="Preload"></div>
      </div>`,
    created() {
        this.reloadScheduleLoop();
    },
    activated() {
        this.showingCurrentProgram = true;
        // this.cyclePrograms();
    },
    deactivated() {
        if (this.reloadScheduled) {
            this.reloadScheduled = false;
            this.loadPrograms();
        }
    },
    methods:
        {
            loadPrograms() {
                let request = new XMLHttpRequest();
                request.responseType = "json";
                request.addEventListener("load", (event) => {

                    this.currentProgram = event.target.response.current;
                    this.currentProgram.start = new Date(this.currentProgram.start);
                    this.currentProgram.end = new Date(this.currentProgram.end);
                    this.nextProgram = event.target.response.next;
                    this.nextProgram.start = new Date(this.nextProgram.start);
                    this.nextProgram.end = new Date(this.nextProgram.end);

                    if (this.currentProgramIndex >= 0) {
                        this.reset();
                    }

                    this.programs.splice(0, this.programs.length);
                    event.target.response.schedule.forEach((program) => {
                        if (program.id === this.currentProgram.id) {
                            return;
                        }

                        program.start = new Date(program.start);
                        program.end = new Date(program.end);
                        this.programs.push(program);
                    });

                    this.error = false;
                });
                request.addEventListener("error", (event) => {
                    console.log(event);
                    this.error = true;
                });
                request.open("GET", "https://rn7scherm.mathijsvos.nl/info.php?apiKey=sTJMi2eFiYheV28EON2SStqZXmnRD3qQipo4MLnJ");
                request.send();
            },
            determineNextProgramIndex: function () {
                let programsAvailable = [];
                this.programs.forEach((element, index) => {
                    if (this.programsShown.indexOf(index) < 0) {
                        programsAvailable.push(index);
                    }
                });

                if (programsAvailable.length === 0) {
                    return -1;
                }

                let randomIndex = programsAvailable[Math.floor(Math.random() * programsAvailable.length)];
                this.programsShown.push(randomIndex);
                return randomIndex;
            }, cyclePrograms() {
                this.showingCurrentProgram = false;

                if (this.programsShown.length >= this.programs.length) {
                    // Reset
                    this.reset();
                    return;
                }

                if (this.nextProgramIndex === -1) {
                    this.currentProgramIndex = this.determineNextProgramIndex();
                    this.nextProgramIndex = this.determineNextProgramIndex();
                } else {
                    this.currentProgramIndex = this.nextProgramIndex;
                    this.nextProgramIndex = this.determineNextProgramIndex();
                }
            },
            reloadScheduleLoop() {
                if (this.programs.length === 0) {
                    this.loadPrograms();
                } else {
                    this.reloadScheduled = true;
                }

                let now = new Date();
                var millisTillRestart = new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), 1, 0, 0) - now;
                if (millisTillRestart < 0) {
                    millisTillRestart += 3600 * 1000;
                }

                setTimeout(this.reloadScheduleLoop, millisTillRestart);
            },
            reset() {
                this.programsShown = [];
                this.nextProgramIndex = -1;
                this.$emit('next');
            }
        }
});