import Vue from "vue/dist/vue.esm";
// import {gsap, CSSPlugin, Power4, Back, Elastic, Linear} from "../gsap-public/esm/";
import {gsap, CSSPlugin, Power4, Back, Elastic, Linear} from "gsap";

gsap.registerPlugin(CSSPlugin);

Vue.component('slide-program-renderer', {
    props: ['program'],
    data: function () {
        return {}
    },
    template: `
      <div class="program-slide">
      <div class="program-title">
        <div class="program-title-wrapper" ref="programTitle">
          <fitty :options="{maxSize: 250}">
            <template v-slot:content>{{ program.title }}</template>
          </fitty>
        </div>
<!--        <div class="program-logo">-->
<!--          <img src="images/7.png" alt="logo">-->
<!--        </div>-->
      </div>
      <div class="program-datetime-filler"></div>
      <div class="program-datetime" ref="programDatetime">
        <div class="program-datetime-label">
          <fitty>
            <template v-slot:content>Volgende uitzending</template>
          </fitty>
        </div>
        <div class="program-date">
          <fitty>
            <template v-slot:content>
              {{ programDayString }}
            </template>
          </fitty>
          <fitty>
            <template v-slot:content>
              {{ programDateString }}
            </template>
          </fitty>
        </div>
        <div class="program-time">
          <fitty>
            <template v-slot:content>
              {{ programTimeString }}
            </template>
          </fitty>
        </div>
      </div>
      <div class="program-picture">
        <div class="program-picture-wrapper" ref="programPictureWrapper">
          <img :src="program.presenters[0].picture" class="img-fluid" ref="programPictureImage" alt="DJ">
        </div>
        <!--<div class="program-picture-label" ref="programPictureLabel">
            <template v-for="(presenter, index) in presenters">
                <span v-html="presenter.replace(' ', '&nbsp;')"></span>
                <template v-if="index < presenters.length - 1">en </template>
            </template>
        </div>-->
      </div>
      </div>`,
    mounted() {
        let timeline = gsap.timeline({onComplete: this.emitNext});
        timeline
            .fromTo(this.$refs.programTitle, {css: {opacity: 0, x: -20}},
                {css: {opacity: 1, x: 0}, ease: Power4.easeOut, duration: 1})
            .fromTo(this.$refs.programPictureWrapper, {css: {x: 300}}, {
                duration: 1,
                css: {opacity: 1, x: 0},
                ease: Power4.easeOut
            }, "+=0")
            .fromTo(this.$refs.programPictureImage, {css: {scale: 1}}, {
                duration: 8,
                css: {scale: 1.1},
                ease: Linear.easeNone
            }, "-=1")
            // .fromTo(this.$refs.programPictureLabel, {css: {x: 300}}, {
            //     duration: 1,
            //     css: {opacity: 1, x: 0},
            //     ease: Power4.easeOut
            // }, "-=7")
            .fromTo(this.$refs.programDatetime, {css: {opacity: 0, y: 100}}, {
                duration: .5,
                css: {opacity: 1, y: 0},
                ease: Power4.easeOut
            }, "-=7")
            .to(this.$refs.programDatetime, {
                duration: 1,
                css: {opacity: 0, x: -300},
                ease: Back.easeIn.config(1)
            }, "+=0")
            // .to(this.$refs.programPictureLabel, {
            //     duration: .5,
            //     css: {opacity: 0, x: 300},
            //     ease: Power4.easeIn
            // }, "-=.5")
            .to(this.$refs.programPictureWrapper, {
                duration: .5,
                css: {opacity: 0, x: 300},
                ease: Power4.easeIn
            }, "-=1")
            .to(this.$refs.programTitle, {duration: 1, css: {opacity: 0, x: 20}, ease: Power4.easeIn}, "-=1");
    },
    computed: {
        programTitle() {
            let title = this.program.title;
            let spaceSplit = title.split(' ');

            if (spaceSplit.length <= 1) {
                return title;
            }

            const halfStringLength = title.length / 2;
            let resultingString = spaceSplit[0];
            let lengthSum = resultingString.length;
            let hasUsedSpace = false;
            spaceSplit.forEach((segment, index) => {
                if (index === 0) {
                    return;
                }

                // + 1 for the space
                if (!hasUsedSpace && lengthSum + segment.length + 1 >= halfStringLength) {
                    resultingString += " " + segment;
                    hasUsedSpace = true;
                } else {
                    resultingString += "&nbsp;" + segment;
                }

                lengthSum += segment.length;
                lengthSum++; // Because of the space itself
            });

            // return resultingString.replace('f/t', '<div class="title-stacker"><div class="stack">for</div><div class="stack">the</div></div>');

            return resultingString;
        },
        presenters() {
            if (this.program.presenters.length === 0) {
                return this.program.presenters;
            }

            if (this.program.presenters.length > 1) {
                return this.program.presenters.map((value) => {
                    return value.name;
                });
            }

            let splitPresenterName = this.program.presenters[0].name.split(" en ");
            if (splitPresenterName.length === 1 || splitPresenterName.length === 0) {
                return this.program.presenters[0].name.split(" & ");
            }

            return splitPresenterName;
        },
        programTimeString() {
            return this.program.start.toLocaleTimeString('nl-NL', {
                    'hour': '2-digit',
                    'minute': '2-digit'
                }).replace(':', '.') + "-" +
                this.program.end.toLocaleTimeString('nl-NL', {
                    'hour': '2-digit',
                    'minute': '2-digit'
                }).replace(':', '.').trim();
        },
        programDayString() {
            return this.program.start.toLocaleDateString('nl-NL', {weekday: 'long'}).trim();
        },
        programDateString() {
            return this.program.start.toLocaleDateString('nl-NL', {day: 'numeric', 'month': 'long'}).trim();
        }
    },
    methods: {
        emitNext() {
            this.$emit('next');
        }
    }
})
;