import Vue from "vue/dist/vue.esm";
import Fitty from "vue-fitty";
import "./slide_program";
import "./slide_static_content";
import "./slide_logo";
import "./slide_weather";
import "./slide_news";
import "./slide_random_video";
import "./slide_video";
import "./slide_random_photo";
import _snowCanvas from "./snow-canvas-master/snow"

Vue.use(Fitty);

//r7vE3GnPhDDWbC5Wqwrc8rkVT
window.app = new Vue({
    el: '#rn7-app',
    data: function () {
        return {
            slides: [],
            cacheableComponents: ['slide_video', 'slide-random-video', 'slide-news', 'slide-weather', 'slide-program', 'slide-random-photo'],
            currentSlideIndex: -1,
            previousSlideIndex: -1,
            snow: {},
            websocket: null,
            fireplaceState: 'idle'
        }
    },
    created() {
        this.slides.push(
            // {
            //     'type': 'static',
            //     'image': 'images/darthfader.jpg'
            // },
            // {
            //     'component': 'slide-random-video',
            //     'sourceUrl': "http://10.0.3.62:8080/Promo's%20Studioscherm/"
            // },
            // {
            //     'component': 'slide-logo'
            // },
            // {
            //     'component': 'slide-news'
            // },
            {
                'component': 'slide-weather'
            },
            {
                'component': 'slide-program'
            }
        );

        this.websocket = this.setupFireplaceWebSocket();
    },
    mounted: function () {
        if ('OffscreenCanvas' in window) {
            let snowCanvas = this.$refs.overlayCanvas;
            snowCanvas.style.backgroundColor = "transparent";
            snowCanvas.width = window.innerWidth;
            snowCanvas.height = window.innerHeight;
            let snowCanvasOffscreen = snowCanvas.transferControlToOffscreen();
            this.snow = new Worker('js/snow-canvas-master/snow_worker-min.js');
            this.snow.postMessage({task: "create", canvas: snowCanvasOffscreen}, [snowCanvasOffscreen]);
            this.snow.postMessage({task: "updateSize", width: snowCanvas.width, height: snowCanvas.height});
        } else {
            this.snow = _snowCanvas({
                el: this.$refs.overlayCanvas, //the canvas element,
                background: "transparent",
                snowColor: "#c6c6c6",
                rMax: 6
            });
        }

        const currentDate = new Date();
        if (currentDate.getMonth() === 11) {
            this.trackNowPlaying();
        }

        if (currentDate.getMonth() === 1 && currentDate.getDate() === 14) // Valentijnsdag
        {
            this.slides.push(
                {
                    'component': 'slide-random-photo',
                    'query': 'valentine\'s day',
                    'text': 'Jij bent onze valentijn!;-)'
                });
        }

        if (currentDate.getMonth() === 11 && currentDate.getDate() >= 6 && currentDate.getDate() < 31) // Kerst
        {
            this.slides.push(
                {
                    'component': 'slide-random-photo',
                    'sourceUrl': 'http://10.0.3.62:8080/fotos%20scherm/kerst/',
                    'text': 'RN7 wenst je heel fijne feestdagen!'
                });
        }

        if (currentDate.getMonth() === 11 && currentDate.getDate() === 31) // Oudjaar
        {
            this.slides.push(
                {
                    'component': 'slide-random-photo',
                    'sourceUrl': 'http://10.0.3.62:8080/fotos%20scherm/oud%20en%20nieuw/',
                    'text': 'RN7 wenst je een goede jaarwisseling!'
                });
        }

        if (currentDate.getMonth() === 0 && currentDate.getDate() === 1) // Nieuwjaar
        {
            this.slides.push(
                {
                    'component': 'slide-random-photo',
                    'sourceUrl': 'http://10.0.3.62:8080/fotos%20scherm/oud%20en%20nieuw/',
                    'text': 'RN7 wenst je een heel gelukkig nieuwjaar!'
                });
        }

        this.currentSlideIndex = 0;

        // Reload page at night
        let now = new Date();
        var millisTillRestart = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 3, 0, 0, 0) - now;
        if (millisTillRestart < 0) {
            millisTillRestart += 3600 * 24 * 1000;
        }

        setTimeout(this.reloadPage, millisTillRestart);
    },
    methods: {
        cycleSlides() {
            if (this.previousSlideIndex !== -1) // Intermezzo slide playing
            {
                return;
            }
            this.currentSlideIndex = (this.currentSlideIndex + 1) % this.slides.length;
        },
        reloadPage() {
            let request = new XMLHttpRequest();
            request.responseType = "text";
            request.addEventListener("load", (event) => {
                window.location.reload(true);
            });
            request.open("GET", "https://rn7scherm.mathijsvos.nl/");
            request.send();

            setTimeout(this.reloadPage, 1000 * 60);
        },
        trackNowPlaying() {
            let request = new XMLHttpRequest();
            request.responseType = "json";
            request.addEventListener("load", (event) => {
                let endTime = new Date(event.target.response.start);
                endTime.setTime(endTime.getTime() + event.target.response.duration * 1000);

                let diff = endTime - new Date();
                if (diff < 10000 || isNaN(diff)) {
                    diff = 10000;
                } else if (diff > 60 * 5 * 1000) {
                    diff = 60 * 5 * 1000;
                }

                // Activate snow
                let itemTitle = event.target.response.title;
                if (itemTitle.length > 1) {
                    const snowWords = ["christmas", "snow", "wonderful time", "jingle bell", "xmas", "cavalry", "santa",
                        "boy child", "little drummer", "mistletoe", "navidad", "new year", "sleigh", "cold outside",
                        "rudolph", "noel", "underneath the tree", "kerst", "x mas", "2000 miles", "sneeuw",
                        "once upon a long ago", "winter", "my only wish", "wonderful dreams", "thames froze"];

                    const excludeSnowWords = ["toth", "jingle", "sweep"];

                    let enableSnow = snowWords.some((element) => {
                        return itemTitle.toLowerCase().includes(element);
                    }) && !excludeSnowWords.some((element) => {
                        return itemTitle.toLowerCase().includes(element);
                    });

                    if (enableSnow) {
                        console.log("Enable snow");
                        if (this.snow.postMessage) {
                            this.snow.postMessage({task: "start"});
                        } else {
                            this.snow.start();
                        }
                    } else {
                        console.log("Disable snow");
                        if (this.snow.postMessage) {
                            this.snow.postMessage({task: "stop"});
                        } else {
                            this.snow.stop();
                        }
                    }
                } else {
                    console.log("Disable snow");
                    if (this.snow.postMessage) {
                        this.snow.postMessage({task: "stop"});
                    } else {
                        this.snow.stop();
                    }
                }

                setTimeout(this.trackNowPlaying, diff);
            });

            request.addEventListener("error", (event) => {
                setTimeout(this.trackNowPlaying, 60000);
            });

            request.open("GET", "https://rn7scherm.mathijsvos.nl/nowplaying.php?apiKey=sTJMi2eFiYheV28EON2SStqZXmnRD3qQipo4MLnJ&" + (new Date()).getTime());
            request.send();
        },
        setupFireplaceWebSocket() {
            const webSocketAddress = "ws://10.0.4.112:13254/";
            let ws = new WebSocket(webSocketAddress);

            ws.onmessage = (message) => {
                // e.data contains received string.
                console.log(message);
                if (message.data === "start") {
                    this.triggerFireplace();
                }
            };

            ws.onopen = (e) => {
                ws.send(this.fireplaceState);
            }

            ws.onerror = (e) => {
                console.log(e);
            }

            ws.onclose = (e) => {
                console.log(e);
                setTimeout(() => {
                    console.log("reconnecting");
                    this.websocket = this.setupFireplaceWebSocket();
                }, 10000);
            }

            return ws;
        },
        triggerFireplace() {
            if (this.fireplaceState !== 'idle') {
                return;
            }

            this.previousSlideIndex = this.currentSlideIndex;
            let slideIndex = this.slides.push(
                {
                    'component': 'slide-video',
                    'video': 'http://10.0.3.62:8081/Haard.mp4'
                }) - 1;

            this.currentSlideIndex = slideIndex;
            this.fireplaceState = 'playing';
            this.websocket.send(this.fireplaceState);

            setTimeout(() => {
                this.currentSlideIndex = this.previousSlideIndex;
                this.slides.splice(slideIndex, 1);
                this.previousSlideIndex = -1;
                this.fireplaceState = 'stopped';
                this.websocket.send(this.fireplaceState);

                setTimeout(() => {
                    this.fireplaceState = 'idle';
                    this.websocket.send(this.fireplaceState);
                }, 1000 * 60 * 10);
            }, 1000 * 60 * 3);
        }
    }
});