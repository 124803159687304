import Vue from "vue/dist/vue.esm";

Vue.component('slide-static-content', {
    props: ['slide'],
    data: function () {
        return {

        }
    },
    template: `<div class="static-slide"><img :src="slide.image" v-if="slide.image" class="h-100"></div>`,
    mounted: function()
    {
        window.setTimeout(this.emitNext, 5000);
    },
    methods: {
        emitNext()
        {
            this.$emit('next');
        }
    }
});