import Vue from "vue/dist/vue.esm";

Vue.component('slide-video', {
    props: ['slide'],
    data: function () {
        return {
            currentVideoIndex: 0,
            startVideo: false,
            playbackStarted: false
        }
    },
    template: `
      <div class="rn7-slide-video">
      <video width="100%" autoplay muted ref="videoPlayer" v-on:ended="rewind" v-on:stalled="emitNext" v-on:play="notifyPlaybackStarted">
        <source :src="slide.video">
      </video>
      </div>`,
    mounted: function () {

    },
    activated() {
        setTimeout(this.checkPlayback, 2000);
        this.$refs.videoPlayer.play();
    },
    deactivated() {
        this.startVideo = false;
        this.$refs.videoPlayer.pause();
    },
    methods: {
        emitNext() {
            this.$emit('next');
        },
        rewind() {
            this.$refs.videoPlayer.currentTime = 0;
            this.$refs.videoPlayer.play();
        },
        notifyPlaybackStarted() {
            this.playbackStarted = true;
        },
        checkPlayback() {
            if (!this.playbackStarted) {
                this.emitNext();
            }
        }
    }
});