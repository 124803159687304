let _snowCanvas = function (obj) {
    /**obj = {
     *  //el must be given, the property with "?" ahead means that this property is alternative
     *       el: element,
     *       ?snowColor:   ,//color of snow, default:"#a6a6a6"
     *       ?backgroundColor: ,//background color, default:"black"
     *       ?maxSpeed: float number, //max speed of snow, default: 3.5
     *       ?minSpeed: float number, //min speed of snow, default: 0.3
     *       ?amount: number, //amount of snow, 150 default
     *       ?rMax: number ,// max radius of snow, default: 4
     *       ?rMin: number, //min  radius of snow, default: 1
     *       ?width: number, // width of canvas, default: window.innerWidth
     *       ?height: number, //height of canvas, default: window.innerHeight
     *   }
     **/

        // check if given the right canvas element
    let canvas = obj.el || document.getElementById("snowCanvas");
    if (!canvas) {
        console.log("please set the canvas element");
        return;
    }
    if (canvas.tagName.toLowerCase() !== "canvas") {
        console.log("please use this function on canvas element");
        return;
    }

    canvas.style.backgroundColor = obj.background || "black"; //set background color
    let fillStyle = obj.snowColor || "#c6c6c6";//color of snow
    //check if given the right color config
    if (!_check(_isColor, [canvas.style.backgroundColor, fillStyle], ["background color", "snow color"])) {
        return;
    }

    let ctx = canvas.getContext("2d");
    const pi2 = 2 * Math.PI;
    let maxSpeed = obj.maxSpeed || 2.5,
        minSpeed = obj.minSpeed || 1.0,
        count = obj.amount || 200, //count of snow
        rMax = obj.rMax || 4, //max radius of snow
        rMin = obj.rMin || 1,
        W, H; //height and width of canvas;
    setHeightWidth(); //initial height and width of canvas;

    //check if given the right number
    if (!_check(_isNumber,
        [maxSpeed, minSpeed, count, rMax, rMin, W, H],
        ["max speed 'maxSpeed'", "min speed 'minSpeed'", "amount", "max radius of snow 'rMax'", "min radius of snow 'rMin'", "width", "height"])) {
        return;
    }

    function setHeightWidth() {
        W = obj.width || window.innerWidth;
        H = obj.height || window.innerHeight;
        canvas.width = W;
        canvas.height = H;
        ctx.fillStyle = fillStyle;
    }

    window.onresize = setHeightWidth;

    const sineLookupSize = 16;
    let sineLookup = [];
    for (let i = 0; i < sineLookupSize; i++) {
        sineLookup[i] = Math.sin((i / sineLookupSize) * pi2);
    }

    function interpolateSine(x) {
        // console.log(x);
        x = ((x % pi2) + pi2) % pi2;
        const rawXIndex = x / pi2 * sineLookupSize;
        const xStartIndex = Math.floor(rawXIndex);
        const xEndIndex = xStartIndex + 1;
        const xStartWeight = xEndIndex - rawXIndex;
        const xEndWeight = 1 - xStartWeight;
        return sineLookup[xStartIndex] * xStartWeight + sineLookup[xEndIndex % sineLookupSize] * xEndWeight;
    }

    let snowGroup = [];
    let running = false;
    let stopping = false;

    function createSnowflake(randomHeight) {
        return {
            x: Math.random() * W - rMax,
            y: randomHeight ? 0 - Math.random() * H : 0,
            r: Math.random() * (rMax - rMin) + rMin,
            s: Math.random() * (maxSpeed - minSpeed) + minSpeed,
            xChangeRate: Math.random() * 1.6 - 0.8
        };
    }

    function resetSnowflake(snowFlake) {
        snowFlake.r = Math.random() * (rMax - rMin) + rMin;
        snowFlake.s = Math.random() * (maxSpeed - minSpeed) + minSpeed;
        snowFlake.xChangeRate = Math.random() * 1.6 - 0.8;
    }

    function drawSnowflake(p) {
        ctx.moveTo(p.x, p.y);
        ctx.arc(p.x, p.y, p.r, 0, pi2);
    }

    function draw() {
        delta += 0.01;
        ctx.beginPath();
        ctx.clearRect(0, 0, W, H);
        snowGroup.forEach((snowFlake, index) => {
            drawSnowflake(snowFlake);
        });
        ctx.fill();

        snowGroup.forEach((snowFlake, index) => {
            updateSnowflakePosition(snowFlake, index);
        });

        if (stopping && snowGroup.length === 0) {
            running = false;
            stopping = false;
        }

        if (!running && !stopping) {
            console.log("Stopped");
        }

        if (running || stopping) {
            requestAnimationFrame(draw);
        }
    }

    let delta = 0;

    function updateSnowflakePosition(p, i) {
        p.y += p.s;
        p.x += interpolateSine(delta + p.xChangeRate) * p.xChangeRate;
        // p.x += Math.sin(delta + p.xChangeRate) * p.xChangeRate;
        if (p.x <= W + p.r && p.y <= H + p.r && p.x >= -p.r) {
            return;
        }

        if (stopping) {
            snowGroup.splice(i, 1);
            return;
        }

        resetSnowflake(p);
        const randomStartPostion = Math.floor(Math.random() * 3);
        switch (randomStartPostion) {
            case 0:
                //drop from top
                p.x = Math.random() * W;
                p.y = -p.r;
                break;
            case 1:
                //start from left
                p.x = -p.r;
                p.y = Math.random() * H;
                break;
            case 2:
                //start from right
                p.x = W + p.r;
                p.y = Math.random() * H;
                break;
        }
    }

    return {
        start() {
            if (running) {
                return;
            }

            delta = 0;

            snowGroup = [];

            for (let i = 0; i < count; i++) {
                snowGroup.push(createSnowflake(true));
            }

            running = true;
            stopping = false;
            requestAnimationFrame(draw)
        },
        stop() {
            stopping = true;
        }
    }
}

let _isColor = function (color) {
    let color2 = "";
    let el = document.createElement("i");
    el.style.background = "";
    el.style.background = color;
    color2 = el.style.background;
    if (color2.length === 0) {
        return false;
    }
    el.style.borderColor = "";
    return true;
}

let _check = (checkFunc, checkItemArr, warningStringArr) => {
    for (let i = 0; i < checkItemArr.length; i++) {
        if (!checkFunc(checkItemArr[i])) {
            console.log("_snowCanvas: please set the right " + warningStringArr[i] + ".");
            return false;
        }
    }
    return true;
}

let _isNumber = (n) => {
    return !isNaN(parseFloat(n)) && isFinite(n);
}


export default _snowCanvas;