import Vue from "vue/dist/vue.esm";
import {gsap, CSSPlugin} from "gsap";

gsap.registerPlugin(CSSPlugin);

Vue.component('slide-current-program-renderer', {
    props: ['current', 'next'],
    data: function () {
        return {
            isCurrentProgram: true
        }
    },
    template: `
      <div class="current-program-slide">
      <div class="w-100 h-100 d-flex position-absolute justify-content-center align-items-end flex-column">
        <div class="current-program-picture w-100 h-100 d-flex justify-content-center align-items-end" ref="picture">
          <img :src="program.presenters[0].picture" class="img-fluid" ref="programPictureImage" alt="DJ">
        </div>
      </div>
      <div class="w-100 h-100 d-flex justify-content-center align-items-stretch flex-column">
<!--        <div class="current-program-header text-left" ref="header">-->
<!--          {{ headerText }}-->
<!--        </div>-->
        <div class="current-program-title h-100">
          <div class="current-program-title-container" ref="programName">
            <fitty>
              <template v-slot:content>{{ headerText }}</template>
            </fitty>
          </div>
        </div>
<!--        <div class="current-program-footer text-right" ref="footer">-->
<!--          <span><template v-if="isCurrentProgram">tot</template><span-->
<!--              class="current-program-endtime">{{ currentProgramTimeString }}</span></span>-->
<!--        </div>-->
        <div class="current-program-presenters text-center position-absolute" style="bottom: 0" ref="presenters">
          met
          <template v-for="(presenter, index) in program.presenters">
            <span v-html="presenter.name.replace(' ', '&nbsp;')"></span>
            <template v-if="index < program.presenters.length - 1"> en</template>
          </template>
        </div>

        <!--        <hr>-->
        <!--        <h3>Hierna</h3>-->
        <!--        <h2>{{ next.title }}</h2>-->
      </div>
      </div>`,
    mounted() {
        if ((this.current.nonstop && this.next.nonstop) || !this.program.title) {
            this.$emit('next');
            return;
        }

        this.runAnimation();
    },
    computed: {
        currentProgramTimeString() {
            if (!this.program.hasOwnProperty("end")) {
                return "";
            }

            let endString = this.program.end.toLocaleTimeString('nl-NL', {
                'hour': '2-digit',
                'minute': '2-digit'
            }).replace(':', '.');

            if (this.isCurrentProgram) {
                return endString;
            }

            let startString = this.program.start.toLocaleTimeString('nl-NL', {
                'hour': '2-digit',
                'minute': '2-digit'
            }).replace(':', '.');

            return startString + "-" + endString;
        },
        // programDateString()
        // {
        //     return this.program.start.toLocaleDateString('nl-NL', {weekday: 'long', day: 'numeric', 'month': 'long'});
        // }
        program() {
            if (this.isCurrentProgram) {
                return this.current;
            }

            return this.next;
        },
        headerText() {
            if (this.isCurrentProgram) {
                return this.program.title;
                // return "Je luistert naar";
            }

            if (this.program.start - new Date() > 1000 * 60 * 15) {
                return "Hierna: " + this.program.title;
            }

            return "Zometeen:" + this.program.title;
        }
    },
    methods: {
        emitNext() {
            if(!this.isCurrentProgram) {
                this.$emit('next');
                return;
            }

            this.isCurrentProgram = false;
            if (!this.program.nonstop) {
                this.runAnimation();
                return;
            }

            this.$emit('next');
        },
        runAnimation() {
            let timeline = gsap.timeline({onComplete: this.emitNext});
            timeline
                .fromTo(this.$refs.header, {css: {x: -50}}, {css: {opacity: 1, x: 0}, ease: "power4.out", duration: 1})
                .fromTo(this.$refs.programName, {css: {x: 0}}, {
                    css: {opacity: 1},
                    ease: "power4.out",
                    duration: 1
                }, "-=1")
                .fromTo(this.$refs.footer, {css: {x: 50}}, {
                    css: {opacity: 1, x: 0},
                    ease: "power4.out",
                    duration: 1
                }, "-=1")
                .to(this.$refs.presenters, {css: {opacity: 1, x: -50}, ease: "power4.out", duration: 1}, "-=1")
                .to(this.$refs.header, {css: {x: 100}, ease: "none", duration: 6}, "-=0.5")
                .to(this.$refs.footer, {css: {x: -50}, ease: "none", duration: 6}, "-=6")
                .to(this.$refs.header, {css: {opacity: 0, x: 110}, ease: "none", duration: 1}, "-=1")
                .to(this.$refs.footer, {css: {opacity: 0, x: -60}, ease: "none", duration: 1}, "-=1")
                .to(this.$refs.programName, {css: {opacity: 0, x: 50}, ease: "power4.in", duration: 2}, "-=1")
                .to(this.$refs.presenters, {css: {opacity: 0, x: -100}, ease: "power4.in", duration: 1}, "-=2");
        }
    }
})
;