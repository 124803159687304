import Vue from "vue/dist/vue.esm";
import {gsap, CSSPlugin, Power4, Linear, Back, Elastic} from "gsap";

gsap.registerPlugin(CSSPlugin);

Vue.component('slide-random-photo', {
    props: ['slide'],
    data: function () {
        return {
            photo: {
                url: ''
            },
            availablePhotos: [],
            currentPhotoIndex: -1
        }
    },
    template: `
      <div class="rn7-slide rn7-slide-random-photo">
      <img :src="photo.url" ref="pic" class="random-photo-photo" alt="Foto">
      <p v-if="photo.user">Foto door {{ photo.user }} @ random-photo</p>
      <h3>{{ slide.text }}</h3>
      </div>`,
    mounted() {
        this.updatePhoto();
    },
    activated() {
        let timeline = gsap.timeline({onComplete: this.emitNext});
        timeline
            .fromTo(".random-photo-photo", {css: {opacity: 1, scale: 1}},
                {css: {scale: 1.1}, ease: Linear.easeNone, duration: 20});
        // .to(".random-photo-photo", {css: {opacity: 0}, duration: 1});
    },
    deactivated() {
        setTimeout(this.updatePhoto, 5000);
    },
    methods: {
        updatePhoto() {
            if (this.slide.query) {
                let request = new XMLHttpRequest();
                request.responseType = "json";
                request.addEventListener("load", (event) => {
                    this.photo = event.target.response;
                    this.error = false;
                });
                request.addEventListener("error", (event) => {
                    console.log(event);
                    this.error = true;
                });
                request.open("GET", "https://rn7scherm.mathijsvos.nl/unsplash.php?RN7=1&search=" + this.slide.query);
                request.send();
            } else {
                let request = new XMLHttpRequest();
                request.responseType = "document";
                request.addEventListener("load", (event) => {
                    this.availablePhotos = Array.from(event.target.responseXML.getElementsByTagName("a")).map((element) => {
                        return element.getAttribute("href");
                    }).filter((element) => {
                        return element.endsWith(".jpg");
                    });

                    if (this.availablePhotos.length === 0) {
                        this.currentPhotoIndex = -1;
                    } else {
                        this.currentPhotoIndex = (this.currentPhotoIndex + 1) % this.availablePhotos.length;
                        this.photo.url = this.slide.sourceUrl + this.availablePhotos[this.currentPhotoIndex];
                    }
                });

                request.addEventListener("error", (event) => {
                    console.log(event);
                });

                request.open("GET", this.slide.sourceUrl);
                request.send();
            }
        },
        emitNext() {
            this.$emit('next');
        }
    }
});